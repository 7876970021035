import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './LatestServices.module.css';
import ServicesCard from './ServicesCard';
import LandingServicesPage from '../../LandingServicesPage';

const LatestServices = () => {
  return (
    <div className={css.latestServicesWrapper}>
      <div className={css.searvicesBox}>
        <div className={css.servicesHeading}>Latest Services</div>
        <div className={css.searvicesFilter}>
          <div className={css.selectBox}>
            <select>
              <option>Categories</option>
            </select>
          </div>
          <div className={css.selectBox}>
            <select>
              <option>Services</option>
            </select>
          </div>
          <div className={css.selectBox}>
            <select>
              <option>Budget</option>
            </select>
          </div>
          <div className={css.selectBox}>
            <select>
              <option>Ratings</option>
            </select>
          </div>
          <div className={css.selectBox}>
            <select>
              <option>Nearby</option>
            </select>
          </div>
        </div>
      </div>
      <div className={css.searvicesCard}>
        <LandingServicesPage />
      </div>
    </div>
  );
};

LatestServices.defaultProps = {
  className: null,
  rootClassName: null,
};

LatestServices.propTypes = {
  className: string,
  rootClassName: string,
};

export default LatestServices;
