import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './NewsLetter.module.css';
import { BrandingIconCard } from '../../../../components';

const NewsLetter = () => {
    return (
        <div className={css.newsLetterWrapper}>
            <div className={css.newsletterBox}>
                <div className={css.joinNewsletter}>Join Our Newsletter</div>
                <div className={css.productPromotion}>Sign up for deals, new products and promotions</div>
                <div className={css.newsletterInput}>
                    <span className={css.emailIcon}>
                        <BrandingIconCard type="email" />
                    </span>
                    <input type="text" placeholder='Email address' />
                    <span className={css.signupLink}>Signup</span>
                </div>
            </div>
        </div>
    )
};

NewsLetter.defaultProps = {
    className: null,
    rootClassName: null,
};

NewsLetter.propTypes = {
    className: string,
    rootClassName: string,
};

export default NewsLetter;
