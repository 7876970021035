import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './SlotsSection.module.css';
import slotImage from '../../../../assets/images/slotImage.png';
import slotcenter from '../../../../assets/images/slotcenter.png';

const SlotsSection = () => {
    return (
        <div className={css.slotWrapper}>
            <div className={css.slotRow}>
                <div className={css.slotLeft}>
                    <h2>No more empty
                        slots & downtimes,
                        stay at 💯 capacity</h2>
                    <p>
                        Create, manage, and optimize your deals.
                        Track the performance of your offers and make adjustments to maximize your reach and conversions.
                    </p>
                </div>
                <div className={css.slotRight}>
                    <div className={css.bottomleft}>
                        <img src={slotImage} className={css.slotImage} />
                        <span className={css.centerImage}>
                            <img src={slotcenter} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

SlotsSection.defaultProps = {
    className: null,
    rootClassName: null,
};

SlotsSection.propTypes = {
    className: string,
    rootClassName: string,
};

export default SlotsSection;
