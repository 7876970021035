import { bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutSingleColumn, Page } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { getMetadata } from '../../PageBuilder/PageBuilder';
import TopbarContainer from '../../TopbarContainer/TopbarContainer';
import CustomerAlgoliaPage from '../CustomerAlgoliaPage';
import CustomerFooter from './CustomerFooter/CustomerFooter';
import CustomerHero from './CustomerHero/CustomerHero';
import css from './CustomerHomePage.module.css';
import DealTypes from './DealTypes/DealTypes';
import LatestServices from './LatestServices/LatestServices';
import NewsLetter from './NewsLetter/NewsLetter';
import PopularDeals from './PopularDeals/PopularDeals';
import TopCategories from './TopCategories/TopCategories';

/**
 * CustomerHomePage Component
 *
 * This component serves as the main landing page for customers.
 * It includes various sections such as a search hero, latest services,
 * popular deals, and more. It also manages search state and handles
 * location submissions.
 *
 * Props:
 * - intl: Internationalization object for translations.
 * - scrollingDisabled: Boolean to disable scrolling on the page.
 * - pageAssetsData: Metadata and other assets for the page.
 */
export const CustomerHomePage = props => {
  const { intl, scrollingDisabled, pageAssetsData } = props;

  // Extract metadata for the page
  const meta = pageAssetsData?.meta ? { ...pageAssetsData.meta } : {};
  const pageMetaProps = getMetadata(meta);

  // State to manage search input and location data
  const [searchQuery, setSearchQuery] = useState('');
  const [locationQuery, setLocationQuery] = useState({});
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();

  /**
   * Handle the submission of the location search.
   * This function updates the location state with the submitted values.
   *
   * @param {Object} values - The submitted values from the location search.
   */
  const handleLocationSubmit = values => {
    setLocationQuery(values);
  };

  return (
    <Page {...pageMetaProps} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn
        className={css.merchantMainWrapper}
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer />}
        // footer={<CustomerFooter />}
      >
        <div className={css.mainWrapper}>
          <div className={css.innerBox}>
            <CustomerHero
              searchQuery={searchQuery} // Current search query
              setSearchQuery={setSearchQuery} // Function to update the search query
              history={history} // History object for navigation
              routeConfiguration={routeConfiguration} // Route configuration context
              config={config} // App configuration context
              handleLocationSubmit={handleLocationSubmit} // Function to handle locationsubmission
              locationQuery={locationQuery} // Current location data
            />
            <LatestServices />
            <TopCategories />
            <PopularDeals />
            <CustomerAlgoliaPage />
            <DealTypes />
            <NewsLetter />
            <CustomerFooter />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

// Default props for the component
CustomerHomePage.defaultProps = {
  currentUser: null,
  verificationError: null,
};

// Prop types for validation
CustomerHomePage.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  submitVerification: func.isRequired,
  isVerified: bool,
  emailVerificationInProgress: bool.isRequired,
  verificationError: propTypes.error,

  // Router location object
  location: shape({
    search: string,
  }).isRequired,

  // Internationalization object
  intl: intlShape.isRequired,
};

export default CustomerHomePage;
