import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './TopCategories.module.css';
import servicesImage from '../../../../assets/images/categories.png';
import { categoriesListing } from '../../LandingPage.duck';
import { useDispatch, useSelector } from 'react-redux';

const TopCategories = () => {
  const dispatch = useDispatch();
  const [topCategories, setTopCategories] = useState([]);

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        const data = await dispatch(categoriesListing());
        setTopCategories(data);
      } catch (error) {
        console.error('Failed to fetch top categories:', error);
      }
    };

    // Call the async function
    fetchData();
  }, [dispatch]); // Add dispatch to dependencies to avoid potential issues with stale closures

  return (
    <div className={css.topCategoriesWrapper}>
      <div className={css.topCategoriesHeading}>Top Categories</div>
      <div className={css.categoriesCard}>
        {Array.isArray(topCategories)
          ? topCategories?.map((item, i) => (
              <div key={i} className={css.categoriesList}>
                <div className={css.catgegoriesimage}>
                  <img src={servicesImage} alt={`Category ${i}`} />
                </div>
                <div className={css.categoriesTitle}>{item.label}</div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

TopCategories.defaultProps = {
  className: null,
  rootClassName: null,
};

TopCategories.propTypes = {
  className: string,
  rootClassName: string,
};

export default TopCategories;
