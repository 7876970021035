import { string } from 'prop-types';
import React from 'react';
import BrandingIconCard from '../../../../components/BrandingIconCard/BrandingIconCard';
import { createResourceLocatorString } from '../../../../util/routes';
import TopbarSearchForm from '../../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import css from './CustomerHero.module.css';

/**
 * CustomerHero Component
 *
 * This component serves as a search interface that allows users to find and book services
 * in their vicinity. It provides input fields for service keywords and location, as well as
 * a button to initiate the search.
 *
 * Props:
 * - searchQuery: Current value of the search input for services.
 * - setSearchQuery: Function to update the search query state.
 * - history: History object for programmatic navigation.
 * - routeConfiguration: Configuration object for defining routes.
 * - config: Configuration for the application used in search form.
 * - handleLocationSubmit: Function to handle the submission of location search.
 * - locationQuery: Current value for the location input (not used directly here).
 */
const CustomerHero = ({
  searchQuery,
  setSearchQuery,
  history,
  routeConfiguration,
  config,
  handleLocationSubmit,
  locationQuery,
}) => {
  return (
    <div className={css.customerHeroWrapper}>
      <div className={css.customerSearch}>
        <h2>The best deals are found here 👇🏻</h2>
        <p>... find & book services near you</p>
        <div className={css.searchBar}>
          {/* Service search input */}
          <div className={css.searchServices}>
            <span className={css.searchIcon}>
              <BrandingIconCard type="search" />
            </span>
            <input
              onChange={e => setSearchQuery(e.target.value)} // Update search query on input change
              name="searchQuery"
              value={searchQuery} // Controlled input
              type="text"
              placeholder="Search for a service" // Placeholder text
            />
          </div>

          {/* Location search input */}
          <div className={css.searchServices}>
            <span className={css.searchIcon}>
              <BrandingIconCard type="location" />
            </span>
            <TopbarSearchForm
              className={css.topbarSearch}
              desktopInputRoot={css.topbarSearchWithLeftPadding}
              onSubmit={handleLocationSubmit} // Function to handle location search submission
              initialValues={{}} // Initial values for the search form
              appConfig={config} // Pass app configuration to the form
            />
          </div>

          {/* Search button */}
          <div className={css.searchButton}>
            <button
              onClick={() => {
                // Navigate to the search results page with the search query as a parameter
                history.push(
                  createResourceLocatorString(
                    'AlgoliaSearchPage', // Target page
                    routeConfiguration, // Route configuration
                    { listingType: 'services' }, // Path parameters
                    { keywords: searchQuery } // Query parameters
                  )
                );
              }}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Default props for the component
CustomerHero.defaultProps = {
  className: null,
  rootClassName: null,
};

// Prop types for validation
CustomerHero.propTypes = {
  className: string,
  rootClassName: string,
};

export default CustomerHero;
