import React from 'react';
import Page from '../../components/Page/Page';
import LayoutSingleColumn from '../../components/LayoutComposer/LayoutSingleColumn/LayoutSingleColumn';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './DashboardPage.module.css';
import { bool } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { compose } from 'redux';
import { BrandingIconCard } from '../../components';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';

export const data = [
  ['Pizza', 'Popularity'],
  ['Pizza', 10],
  ['Pepperoni', 45],
  ['Hawaiian', 45],
];

export const databar = [
  ['Year', ''],
  ['2014', 200],
  ['2015', 250],
  ['2016', 300],
  ['2017', 350],
];

export const dataLines = [
  ['Year', 'Sales', 'Expenses'],
  ['2013', 1000, 400],
  ['2014', 1170, 460],
  ['2015', 660, 1120],
  ['2016', 1030, 540],
];

const BrandingIconTypes = {
  DEALS: 'deals',
  SOLD: 'sold',
  TRENDUP: 'trentup',
  TOTAL: 'total',
};

const dealsCard = [
  {
    number: '12',
    name: 'Active Deals',
    icon: BrandingIconTypes.DEALS,
  },
  {
    number: '250',
    name: 'Sold Today',
    icon: BrandingIconTypes.SOLD,
  },
  {
    number: '98',
    name: 'Total Deals',
    icon: BrandingIconTypes.TRENDUP,
  },
  {
    number: '12,809',
    name: 'Total Sales',
    icon: BrandingIconTypes.TOTAL,
  },
];

const badgeIcon = {
  BUCKET: 'bucket',
  BUZZ: 'buzz',
  SCANQR: 'scanqr',
  ACCEPTCODE: 'acceptcode',
};

const badgeCard = [
  {
    icon: badgeIcon.BUCKET,
    heading: 'Create Deal',
  },
  {
    icon: badgeIcon.BUZZ,
    heading: 'Send A Buzz',
  },
  {
    icon: badgeIcon.SCANQR,
    heading: 'Scan QR',
  },
  {
    icon: badgeIcon.ACCEPTCODE,
    heading: 'Accept Code',
  },
];

const DashboardPageComponent = props => {
  const { currentUser } = useSelector(state => state?.user);
  const { intl, scrollingDisabled } = props;

  return (
    <Page title={''} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn
        className={css.mainLayout}
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer />}
      >
        <div className={css.dashboardWrapper}>
          <div className={css.dashboardHeader}>
            <div className={css.dashboardHeading}>
              Hello {currentUser?.attributes?.profile?.firstName},
            </div>
            <div className={css.dashboardSubHeading}>
              Here’s a summary of your business performance
            </div>
          </div>
          <div className={css.dashboardBody}>
            <div className={css.dealsCardsWrapper}>
              {dealsCard.map((item, i) => {
                return (
                  <div className={css.dealCard}>
                    <div className={css.cardLeft}>
                      <div className={css.number}>{item.number}</div>
                      <div className={css.heading}>{item.name}</div>
                    </div>
                    <div className={css.cardRight}>
                      <BrandingIconCard type={item.icon} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={css.badgeCardBox}>
              {badgeCard.map((item, i) => {
                return (
                  <div className={css.badgeCard}>
                    <div>
                      <BrandingIconCard type={item.icon} />
                    </div>
                    <div className={css.heading}>{item.heading}</div>
                  </div>
                );
              })}
            </div>
            <div className={css.cartList}>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Fulfilment Ratio</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.subHeading}>This Week</div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="PieChart"
                    data={data}
                    // options={options}
                    width={'100%'}
                    height={'100%'}
                  />
                </div>
              </div>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Sales Volume</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="100%"
                    data={databar}
                  // options={options}
                  />
                </div>
              </div>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Total Revenue</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="100%"
                    data={data}
                  // options={options}
                  />
                </div>
              </div>
            </div>
            <div className={css.bookingOrder}>
              <div className={css.bookingHeader}>
                <div className={css.bookingHeading}>Latest Bookings & Orders</div>
                <div className={css.moreLink}>
                  <a href="#">
                    More
                    <BrandingIconCard type="rightarrow" />
                  </a>
                </div>
              </div>
              <div className={css.bookingTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Service</th>
                      <th>Customer </th>
                      <th>Deal</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Amount</th>
                      <th>Deal Type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className={css.heading}>Sharp Crew Cut</span>
                      </td>
                      <td>
                        <span className={css.mainHeading}>Safiya Mohamed</span>
                      </td>
                      <td>
                        <span className={css.mainHeading}>Y</span>
                      </td>
                      <td>
                        <span className={css.mainHeading}>Feb 5, 2020</span>
                      </td>
                      <td>
                        <span className={css.mainHeading}>09:00am</span>
                      </td>
                      <td>
                        <span className={css.mainHeading}>SAR60.76</span>
                      </td>
                      <td>
                        <span className={css.amountDiscountBadge}>Amount Discount</span>
                      </td>
                      <td>
                        <span className={css.bookedText}>Booked</span>
                      </td>
                      <td>
                        <div className={css.actionButton}>
                          <span className={css.actionIcon}>
                            <BrandingIconCard type="edit" />
                          </span>
                          <span className={css.actionIcon}>
                            <BrandingIconCard type="delete" />
                          </span>
                          <span className={css.actionIcon}>
                            <BrandingIconCard type="horizantaldots" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

DashboardPageComponent.defaultProps = {};

DashboardPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const DashboardPage = compose(injectIntl)(DashboardPageComponent);

export default DashboardPage;
