import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './PopularDeals.module.css';
import PopularDealsCard from './PopularDealsCard';
// import ServicesCard from './ServicesCard';

const PopularDeals = () => {
  return (
    <div className={css.popularDealsWrapper}>
      <div className={css.polularBox}>
        <div className={css.servicesHeading}>Popular Deals</div>
        <div className={css.searvicesFilter}>
          <div className={css.selectBox}>
            <select>
              <option>Categories</option>
            </select>
          </div>
          <div className={css.selectBox}>
            <select>
              <option>Services</option>
            </select>
          </div>
          <div className={css.selectBox}>
            <select>
              <option>Budget</option>
            </select>
          </div>
          <div className={css.selectBox}>
            <select>
              <option>Ratings</option>
            </select>
          </div>
          <div className={css.selectBox}>
            <select>
              <option>Nearby</option>
            </select>
          </div>
        </div>
      </div>
      <div className={css.popularBody}>
        <div className={css.discountVoucher}>
          <div className={css.standardText}>Get up to</div>
          <div className={css.largeDiscount}>60%</div>
          <div className={css.standardText}>discount on deals</div>
        </div>
      </div>
      {/* <div className={css.popularCardGrid}>
                <PopularDealsCard />
            </div> */}
    </div>
  );
};

PopularDeals.defaultProps = {
  className: null,
  rootClassName: null,
};

PopularDeals.propTypes = {
  className: string,
  rootClassName: string,
};

export default PopularDeals;
