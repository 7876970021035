import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './CustomerFooter.module.css';
import { BrandingIconCard } from '../../../../components';

const CustomerFooter = () => {
    return (
        <div className={css.customerFooterWrapper}>
            <div className={css.footerBox}>
                <div className={css.footerTopLeft}>
                    <div className={css.logo}>
                        <BrandingIconCard type="logo" />
                    </div>
                    <div className={css.bookingDeals}>Appointment Booking & Deals Management</div>
                </div>
                <div className={css.footerTopRight}>
                    <a href='#'>Home</a>
                    <a href='#'>Deals</a>
                    <a href='#'>Services</a>
                    <a href='#'>Become a Merchant</a>
                    <a href='#'>Contact Us</a>
                </div>
            </div>
            <div className={css.footerBottom}>
                <div className={css.footerBottomLeft}>
                    <div className={css.leftText}>
                        Copyright © 2024 NOW!. All rights reserved
                    </div>
                    <div className={css.leftText}>Privacy Policy</div>
                    <div className={css.leftText}>Terms of Use</div>
                </div>
                <div className={css.footerBottomRight}>
                    <a href='#'>
                        <BrandingIconCard type="footerinstagram" />
                    </a>
                    <a href='#'>
                        <BrandingIconCard type="footerfacebook" />
                    </a>
                    <a href='#'>
                        <BrandingIconCard type="footeryoutube" />
                    </a>
                </div>
            </div>
        </div>
    )
};

CustomerFooter.defaultProps = {
    className: null,
    rootClassName: null,
};

CustomerFooter.propTypes = {
    className: string,
    rootClassName: string,
};

export default CustomerFooter;
