import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './PopularDealsCard.module.css';
import servicesImage from '../../../../assets/images/discontCard.png';
import BrandingIconCard from '../../../../components/BrandingIconCard/BrandingIconCard';

const servicesDetail = [
    {
        image: servicesImage,
        title: "Naturally Extracted Honey Hair Blow",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
    {
        image: servicesImage,
        title: "Sharp Crew Cut",
        servicesName: "by AlCasa Hair Center",
        price: "SAR 12.00",
    },
]

const PopularDealsCard = () => {
    return (
        <div className={css.popularDealsCardWrapper}>
            <div className={css.cardGrid}>
                {servicesDetail.map((item, i) => {
                    return (
                        <div className={css.card}>
                            <div className={css.cardImage}>
                                <span className={css.discountAmunt}>
                                    -14%
                                </span>
                                <img src={item.image} className={css.cardImage} />
                                <span className={css.favIcon}>
                                    <BrandingIconCard type="dislike" />
                                </span>
                            </div>
                            <div className={css.cardBody}>
                                <div className={css.bodyLeft}>
                                    <h4>{item.title}</h4>
                                    <div className={css.timeOffer}>
                                        <span>Limited Time Offers</span>
                                    </div>
                                    <div className={css.servicesPrice}>
                                        <span className={css.actualPrice}>SAR876</span>
                                        <span className={css.discountPrice}>SAR3.50</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

PopularDealsCard.defaultProps = {
    className: null,
    rootClassName: null,
};

PopularDealsCard.propTypes = {
    className: string,
    rootClassName: string,
};

export default PopularDealsCard;
