import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { NamedLink } from '../../../../components';
import BrandingIconCard from '../../../../components/BrandingIconCard/BrandingIconCard';
import { STAFF_LISTING_TYPE } from '../../../../util/types';
import css from './ServicesCard.module.css';

const ServicesCard = ({ listings = [], intl }) => {
  const { listingType } = useParams();
  return (
    <div className={css.servicesCardWrapper}>
      <div className={css.cardGrid}>
        {listings.map((item, _) => {
          const imageURL = item?.images?.length
            ? item.images[0]?.attributes?.variants['listing-card-2x']?.url
            : '';
          const details = item?.attributes;
          return (
            <NamedLink
              name="EditListingPage"
              params={{ slug: details?.title, id: item?.id?.uuid, type: 'edit', tab: 'details' }}
            >
              <div className={css.card}>
                <div className={css.cardImage}>
                  <img src={imageURL} className={css.cardImage} />
                  <span className={css.favIcon}>
                    <BrandingIconCard type="dislike" />
                  </span>
                </div>
                <div className={css.cardBody}>
                  <div className={css.bodyLeft}>
                    <h4>{item.title}</h4>
                    <div className={css.servicesName}>{details.title}</div>
                    {listingType !== STAFF_LISTING_TYPE && (
                      <div className={css.servicesPrice}>SAR {details?.price?.amount / 100}</div>
                    )}
                  </div>
                  <div className={css.bodyRight}>
                    <button>{intl.formatMessage({ id: 'ViewListingsPage.bodyRight' })}</button>
                  </div>
                </div>
              </div>
            </NamedLink>
          );
        })}
      </div>
      {/* <div className={css.viewMoreLink}>View more</div> */}
    </div>
  );
};

export default memo(ServicesCard);
